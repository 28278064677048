import React from 'react'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import moment from 'moment'
import Layout from "../components/layout";

import Bio from '../components/Bio'
import {graphql} from "gatsby";

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    const dateFmt = moment(post.frontmatter.date).format("MMMM Do, YYYY");


    var scripts = [];
    if (post.frontmatter.js_scripts != null) {
      post.frontmatter.js_scripts.forEach(function(src) {
        scripts.push(<script defer="true" key={src} src={src} type="text/javascript" />);        
      }) 
    }

    return (
        <Layout location={this.props.location}>
      <div>
        <Helmet title={`${post.frontmatter.title} | ${siteTitle}`}>
          <link href="https://paul.querna.org/atom.xml" rel="alternate" title="Paul Querna's Journal" type="application/atom+xml" />
          {scripts}
        </Helmet>

        <div className="page-header">
          <h1>{post.frontmatter.title}</h1>
        </div>

        <p>
          <small>Published {dateFmt}</small>
        </p>

        <div dangerouslySetInnerHTML={{ __html: post.html }} />

        <hr />

        <Bio />

      </div>
        </Layout>
    )
  }
}

export default BlogPostTemplate

export const query = graphql`
  query BlogPostBySlug($slug: String!) {
  site {
      siteMetadata {
      title
      author
  }
  }
  markdownRemark(fields: { slug: { eq: $slug } }) {
  id
  html
  frontmatter {
      title
      date
  }
  }
  }
  `